import React  from 'react';

// Project
import "./nopage.css"

//< init >
var url=window.location.href;
var search=window.location.search;
if( search.startsWith("?")){ 
  var id_old = search.substring(1);
  try {
   if(id_old>1){
      //?123 ->?i=123
     search=window.location.href="/articles/article_read?i=" + id_old;
     }   
  } 
  catch (error) {
   
  }
}


//</ init >
  
export default function GoogleAds_Footer() { 
  //----< HTML-Render >----
  return (
    //----< return: render >----
    <div className="nopage">  
          <div className="card div_message">
            <h1>NoPage 404</h1>
            <div >Page not found</div>
            <p>url={url}</p>
            <p>search={search}</p>
            <p>
            https://codedocu.de/Net-Framework/Asp_dot_Net-Core/jQuery/JS_colon_-URL-Query-Parameter-in-Input-Feld-laden?3640
            </p>
          </div>
        </div>
    //----</ return: render >----
  );
  //----</ HTML-Render >----
  //============</ Component >============
}