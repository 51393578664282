import React, { useState,useEffect } from 'react'
import { useCookies } from 'react-cookie';  //auth_token

// Project
import { encrypt_word} from "../../../helpers/encrypt.ts"
import { get_tempkey } from "../../../helpers/authentication.ts"
import { check_email } from "../../../helpers/check_input_validations.ts"
import "./user_send_password_reset.css"

export default function UserSendPasswordReset() {
  //============< Component >============

  //< variables >
  const api_url = process.env.REACT_APP_API_URL;
  
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [key, setKey] = useState('');
  const [iv, setIV] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  const [isSend, setIsSend] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['XSRF-TOKEN']);
  //</ variables >


  //const navigate = useNavigate()

  //--< init >--
  useEffect(() => {init();}, []);
  useEffect(() => {check_form_inputs();}, [email]);

  async function init(){
    document.title="🔑 User Send Password Reset";
    console.log("antiforgery=" + cookies['XSRF-TOKEN']);
    init_temp_key();
  }
  //--</ init >--
  
  async function init_temp_key(){
    //--------< init_temp_key() >--------
    setError("");
    const server_key_iv = await get_tempkey(); //from authorization service
    const server_key = server_key_iv.substring(0,32);
    const server_iv = server_key_iv.substring(32);
    if( server_key==="" || server_iv ===""){
      setError("No Transmittion Keys available") ;
      setKey("");
      setIV("");
    }
    else
    {
      setKey(server_key);
      setIV(server_iv);
    }  
    //--------</ init_temp_key() >--------
  }

  const onSend = () => {
    //--------< onSend Button-Event() >--------
    //< check >
    let ok_form_inputs = check_form_inputs();
    if (ok_form_inputs === false) return;
    //</ check >

    //--< send to register >--

    //clear cookies
    removeCookie('un',{path:'/'});
    removeCookie('ut',{path:'/'});  
    
    //* finally send it to server if everything ok  
    // Authentication calls will be made here...
    send_password_reset()
    //--</ send to register >--
    //--------</ onSend Button-Event() >--------
  }

  async function send_password_reset(){
    //--------< login_on_server() >--------
    if(key==="" || iv===""){  setError("No Authorization keys available") ;
      return ;
    }

    let encrypted_email= await encrypt_word(email, key, iv);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
          {
            ue: encrypted_email,
          })
    };

    const url= api_url + '/user/user_send_password_reset';
    var data;
    try {
      const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
      if (response?.ok) {
          
          //Navigate to Startpage 
          //window.location.href="/";//"/user/user_login";  
          setIsSend(true);
          //--< OK >--
        }
        else if (response.status===401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status===400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  //--------</ register_on_server() >--------
  }

  function check_form_inputs():boolean{
    //--------< check_form_inputs() >--------
    // Set initial error values to empty
    setEmailError('')
    setError("");
    
    // Check if the user has entered both fields correctly
    let email_check=check_email(email);
    if ( email_check != "" )  {
      setEmailError(email_check)
      return false;
    }

    //alles ok-> true
    return true;
    //--------</ check_form_inputs() >--------
    
  }


  
  if (isSend===true) {    
      return(
      <div className="card div_message">
        <img src="/icons/email-fast.png" alt='input icon' className="input_icon"/> 
        Eine Email zur zurücksetzen wurde an folgende Email-Adresse gesandt:
        <div>{email}</div>      
        <div>es kann eine Minute dauern, bis die email ankommt.</div>      
      </div>
      )
  }

  return (
    <div className="div_form">
      {error && <div style={{ color: 'red' }}>ERROR: {error}</div> }

      {isLoading && <div >loading..</div> }

     
      <div className="divinput">
        <div className="divtitle">
            <img src="/icons/logout-variant.png" alt='login' className="titleicon"/> 
            Send: Reset Password
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/email-outline.png" alt='input icon' className="input_icon"/> 
            <input
              placeholder="Enter your email here"
              onInput={(input_event)=>setEmail(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{emailError}</label>
        </div>

        
        <div className="div_sendbutton">
          <input className="btn btn-primary button_login" type="button" onClick={onSend} value={'Send Email'} />
        </div>
      </div> 
      
      <div className="div_nav_links">
        <a href="/user/user_login" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='register' className="img_navlink"/> login</a>
        <a href="/user/user_register" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='register' className="img_navlink"/> Register</a>        
      </div>

    </div>
  )
}

