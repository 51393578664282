export function  check_username(username:string):string{
  //--------< check_password >--------
  if(username === null){
    return 'Username is empty';
  };
  
  if (username==='') {
    return 'Please enter a username';
  }

  
  return "";
  //--------</ check_password >--------
}

export function  check_password(password:string):string{
    //--------< check_password >--------
    if(password === null){
      return 'Password is empty';
    };
    
    if (password==='') {
      return 'Please enter a password';
    }

    if (password.length < 8) {
      return 'The password must be 8 characters or longer';
    }

    return "";
    //--------</ check_password >--------
  }


  export function  check_email(email:string):string{
    //--------< check_password >--------
    if(email === null){
      return 'Password is empty';
    };
    
    if ('' === email) {
      return 'Please enter a email';
    }

    if ("!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/".test(email)) {
      return 'Please enter a valid email';
    }

    return "";
    //--------</ check_password >--------
  }

  export function  check_confirmword(password:string, confirmWord:string):string{
    //--------< check_password >--------
    if(password !== confirmWord){
      return 'ConfirmWord Does not match';
    };
    
    return "";
    //--------</ check_password >--------
  }

  