import React, { useEffect, useState } from 'react';
import config from "../../config.json"
import './google_ads_footer.css';


export default function GoogleAds_Footer() { 
  useEffect(()=>{
    //----< init() >----
     (window.adsbygoogle = window.adsbygoogle || []).push({});
      //----</ init() >----
    },[])


  //----< HTML-Render >----
  return (
    //----< return: render >----
    <div className="div_google_ads_footer">
      <ins className="adsbygoogle"
        data-ad-client={config.google_ad_client}
        data-ad-slot="9297901752"       
        data-ad-format="auto"
        data-full-width-responsive="true"
        style={{display: "inline-block",height:300,width:"100%"}}   
       ></ins>
    </div>
    //----</ return: render >----
  );
  //----</ HTML-Render >----
    //============</ Component >============
  }
