import React, { useState,useEffect } from 'react'
import { useCookies } from 'react-cookie';  //auth_token, xsrf
import { useSearchParams } from 'react-router-dom';

// Project
import { encrypt_word} from "../../../helpers/encrypt.ts"
import { get_tempkey } from "../../../helpers/authentication.ts"
import { check_password } from "../../../helpers/check_input_validations.ts"
import "./user_confirm_new_user.css"

export default function UserConfirmNewUser() {
  //============< Component >============

  //< variables >
  const api_url = process.env.REACT_APP_API_URL;
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  const [isDone, setIsDone] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['XSRF-TOKEN']);

  //get id from url-parameter : read?id=123
  const [searchParams] = useSearchParams();
  const register_token = searchParams.get('rt'); //register-token
  //</ variables >


  //const navigate = useNavigate()

  //--< init >--
  useEffect(() => {init();}, []);
  
  async function init(){
    document.title="🔑 User Confirm New User";
  }
  //--</ init >--
  
  
  async function onSend(){
    //--------< onSend Button-Event() >--------
    //--< send to server >--
    //* finally send it to server if everything ok  
    // Authentication calls will be made here...
    await confirm_new_user();
    //--</ send to server >--

    //2: Navigate to Startpage 
    window.location.href="/user/user_login"

    //--------</ onSend Button-Event() >--------
  }

  async function confirm_new_user(){
    //--------< confirm_new_user() >--------
    
    //--< prepare send >--
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${register_token}`, //*enthält userguid und validation
       }
       //no body
    };

    const url= api_url + '/user/user_confirm_new_user';
    var data;
    try {
      const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
      if (response?.ok) {
          
          //Navigate to Startpage 
          //window.location.href="/";//"/user/user_login";  
          setIsDone(true);
          //--< OK >--
        }
        else if (response.status===401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status===400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  //--------</ confirm_new_user() >--------
  }

  function check_form_inputs():boolean{
    //--------< check_form_inputs() >--------
    // Set initial error values to empty
    setError("");
    
    //alles ok-> true
    return true;
    //--------</ check_form_inputs() >--------
    
  }

  
  
  if (isDone===true) {    
      return(
      <div className="card div_message">Der neue User wurde bestätigt.
        <div>Sie können sich jetzt hier mit den neuen Daten anmelden.</div>      
        <div className="div_goto_login">Goto Login: <a href="/user/user_login" className="a_link"><img src="/icons/login.png" alt='login' className="userheadermenu_icon_menuitem"/> login</a></div>        
      </div>
      )
  }

  return (
    <div className="div_form">
      {error && <div style={{ color: 'red' }}>ERROR: {error}</div> }

      {isLoading && <div >loading..</div> }

     
      <div className="divinput">
        <div className="divtitle">
            <img src="/icons/account-box.png" alt='new Account' className="titleicon"/> 
            Bestätigen Sie den neuen User-Account:
        </div>

        
        <div className="div_sendbutton">
          <input className="btn btn-primary button_login" type="button" onClick={onSend} value={'Bestätigen'} />
        </div>
      </div> 
      
      <div className="div_nav_links">
        <a href="/user/user_login" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='register' className="img_navlink"/> login</a>
        <a href="/user/user_register" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='register' className="img_navlink"/> Register</a>        
      </div>

    </div>
  )
}

