import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

//*Project
import config from "../../../config.json"
import  "./articles_list.css"

export default function ArticlesListComponent() {
  //============< Component >============
  //--< Variables >--
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  //get filter keywords, folders and search
  const [searchParams] = useSearchParams();
  const search_keywords = searchParams.get('k');
  const search_folders = searchParams.get('f');
  const search_words = searchParams.get('s');
  
  const files_url = process.env.REACT_APP_FILES_URL;
  const api_url = process.env.REACT_APP_API_URL;
  //--</ Variables >--

  
  function init(){
    //----< init() >----
    //< browser title >
    const appTitle=config.AppTitle;
    document.title= "🧾" + appTitle;
    //< browser title >
  }
  //----</ init() >----

  //--< autostart >--
  init();
  
  //< Hooks >
  useEffect(() => {fetchData();}, []); // Pass an empty array to only call the function once on mount.
  //</Hooks >
  //--</ autostart >--

  //====< functions >====
  //*load data from web api
  async function fetchData() {
    setIsLoading(true);

    let url= api_url + '/articles/get_list';
    var query="";
    if(search_words != null) {query = query + "&s=" + encodeURI(search_words)};
    if(search_keywords != null) {query = query + "&k=" + encodeURI(search_keywords)};
    if(search_folders != null) {query = query + "&f=" + encodeURI(search_folders)};
    if(query != "") {       
      url= url + "?" + query.substr(1); 
    }
    var data;
    try {
      const response = await fetch(url);   //*get web_data from web api as json-string
      if (response?.ok) {
          //--< OK >--  
          setIsLoading(false);
          const data = await response.json();  //*convert json to data
          data.forEach(article => {
            if( article.content_Text.length > 100){
              article.content_Text= article.content_Text.substring(0,400);
            }            
          });       
          setData(data);
          //--< OK >--
        }
        else if (response.status==401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status==400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  }

  //====</ functions >====

  //----< HTML-Render >----
  if (isLoading) {    return <div style={{ color: 'black' }}>Loading...</div>; }
  if (error) {    return <div style={{ color: 'red' }}>ERROR: {error}</div>; }
  
  if (data) {
    return (
      <div id="divList" className="div_articles_list_component">
        {data.map(item=>(       
          <div id={"listpad_item_" + item.guidArticle} key={item.guidArticle} className="listpad_items" >
              <div className="divlist_cell_image">
                <a href={"/articles/article_read?i=" + item.guidArticle } title="{{article.content_Title}}" className="link_image">
                  <img src= {files_url + '/files/user_files/articles/images/Thumbnail_' + item.guidArticle + '_1.jpg'} className="list_image"/>  
                </a>          
              </div>                   

              <div className="div_list_text_blocks">
                      
                      <div ><a href={'/articles/article_read?i=' + item.guidArticle} className="item_title">{item.content_Title}</a> </div>
                      <div className="item_text">
                        {item.content_Text}
                        </div>


                      <div id="divshortlinks" className="divshortlinks">
                      <div id="divkeywords" className="divkeywords" >
                        { item.keywords !== null
                          ? <div className="divkeywords">
                            <img src="/icons/text-search.png" alt='keywords' className="icon_list_shortlinks"/>    
                            { (item.keywords).split(";").map(keyword =>
                              <a key={item.guidArticle + "_" + keyword} href={"/articles/articles_list?k=" + encodeURI(keyword)}
                              style={{ margin: '4px' }} className="item_keyword">{keyword}</a>     
                              )}                            
                          </div >
                          : <>                           
                            </>
                        }
                       </div>                
                       <div id="divfolders"  >
                        { item.folders !== null
                          ? <div className="divfolders" >
                            <img src="/icons/layers-search.png" alt='keywords' className="icon_list_shortlinks"/>    
                            { (item.folders).split(";").map(folder =>
                              <a key={item.guidArticle + "_" + folder} href={"/articles/articles_list?f=" + encodeURI(folder)}
                              style={{ margin: '4px' }} className="item_folder">{folder}</a>     
                              )}                            
                          </ div>
                          : <>                           
                            </>
                        }
                        </div>
                      </div>
                      </div>
          </div>

          ))}
      </div>    
    //----</ HTML-Render >----
    )
  }
  //============</ Component >============


}



