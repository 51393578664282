import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//logout and show Username
import { useCookies } from 'react-cookie';  //auth_token, username, logout

// Project
import "./headbar_usermenu.css"

export default function HeadbarUserMenu() {
    //============< Component >============
    //--< useStates >--
    const [username, setUsername] = useState("null");
    const [cookies, setCookie, removeCookie] = useCookies(['un']);    
    //--</ useStates >--

    //--< Init: Hooks >--
    useEffect(() => {init();}, []);
    //--</ Init: Hooks >--

    //==< functions >==
     function init(){
        let un=cookies.un;
        setUsername(un);
        console.log("un(menu)=" + cookies.un);    
    }  

    function logout(){
        //----< logout() >----
        console.log("LOGGED OUT");
        removeCookie('un',{path:'/'});
        removeCookie('ut',{path:'/'});        

        //Navigate to Startpage 
        window.location.href="/";//"/user/user_login";  
        //----</ logout() >----
    }
    //==< functions >==

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
  
    //============< HTML-Output-Render >============
    return (  
        <div className="div_headbar_usermenu_component">
            <Button
                onClick={handleClick}
                //onMouseEnter={handleClick}
                //onMouseLeave={handleClose}
                style={{padding:0}}
            >
               { username 
                ? 
                    <div className="divbutton_account">
                        <div className="div_accountname">{username}</div>   
                        <img src="/icons/account-box.png" alt='user-account' className="icon_userheadermenu"/>                                 
                    </div>
                : 
                    <img src="/icons/account-circle-outline.png" alt='login' className="icon_userheadermenu"/>    
                }
            </Button>
                
            { username 
                ?      
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}                
                >        
                    <MenuItem >
                        <a href="/user/user_account" className="userheadermenu_menuitem">
                            <img src="/icons/card-account-details.png" alt='account' className="userheadermenu_icon_menuitem"/> Account
                        </a>
                    </MenuItem>
                    <MenuItem >
                        <a href="/user/user_change_email" className="userheadermenu_menuitem">
                            <img src="/icons/email-arrow-right-outline.png" alt='change email' className="userheadermenu_icon_menuitem"/> Change Email
                        </a>
                    </MenuItem>
                    <MenuItem >
                        <a href="/user/user_change_password" className="userheadermenu_menuitem">
                            <img src="/icons/form-textbox-password.png" alt='change password' className="userheadermenu_icon_menuitem"/> Change Password
                        </a>
                    </MenuItem>
                    <MenuItem onClick={logout} >
                        <img src="/icons/logout.png" alt='logout' className="userheadermenu_icon_menuitem"/> Logout                        
                    </MenuItem>
                </Menu>
                : 
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}                
                >
                    <MenuItem >
                        <a href="/user/user_login" className="userheadermenu_menuitem">
                            <img src="/icons/login.png" alt='login' className="userheadermenu_icon_menuitem"/> login
                        </a>
                    </MenuItem>
                    <MenuItem >
                        <a href="/user/user_register" className="userheadermenu_menuitem">
                            <img src="/icons/account-key.png" alt='register' className="userheadermenu_icon_menuitem"/> register new
                        </a>
                    </MenuItem>
                    <MenuItem >
                        <a href="/user/user_send_password_reset" className="userheadermenu_menuitem">
                            <img src="/icons/logout-variant.png" alt='send password reset' className="userheadermenu_icon_menuitem"/> reset password
                        </a>
                    </MenuItem>
                </Menu>            
                }            
                
            
            
        </div >
        
    //============</ HTML-Output-Render >============
    )
  //============</ Component >============
}


