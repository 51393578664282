export async function get_tempkey():string{   
    //------< get_tempkey >-------
    const api_url = process.env.REACT_APP_API_URL;

    //get session
    const url= api_url + '/authentication/get_tempkey';
    var data;
    try {
      const response = await fetch(url);   //*get web_data from web api as json-string
      if (response?.ok) {
          //--< OK >--  
          data = await response.text(); //*convert json to data
          return data;
          //--< OK >--
        }
        else {
            return "";
        }
      }
     catch (error) {
        return "";
     }
      
      //------</ get_tempkey >-------
  }