import React  from 'react';
import { useSearchParams } from 'react-router-dom';


//project
import config from "../../../config.json"
import UserHeaderMenu  from "../headbar_usermenu/headbar_usermenu.jsx"
import './headbar.css';

export default function HeadbarComponent() {
  //============< Component >============
  //< init.variablen >
  //get filter keywords, folders and search
  const [searchParams] = useSearchParams();
  const search_keywords = searchParams.get('k');
  const search_folders = searchParams.get('f');
  const search_words = searchParams.get('s');
  let search_default="";
  if (search_words != null) {search_default += " " + search_words; }
  if (search_keywords != null) {search_default += " " + search_keywords; }
  if (search_folders != null) {search_default += " " + search_folders; }  
  //</ init.variablen >
  
  const appTitle=config.AppTitle;


  function onSearch(search_input){
    search_input=search_input.trim();
    var query="";
    if(search_input !== ""){ query="?s=" + encodeURIComponent(search_input)}
    window.location.href="/articles/articles_list" + query;  
  }


  //----< HTML-Render >----
  return (
    //----< return: render >----
    <div className="div_headbar_component">
      <div className="app_navbar">
        <div className="app_label">
          <a href="/" className="app_link">
          {appTitle}
          </a>
          </div>
        <div className="app_shorttext">Code Solutions</div>
        
        
        <div className="divsearch">
          <img src="/icons/magnify.png" alt='search' className="headericon_invert" />
          <input id="input_search" className="form-control" 
            defaultValue={search_default} 
            onKeyDown={(e) => { 
            if (e.key === "Enter") 
                onSearch(e.target.value); 
            }} 
          />
        </div>
        


        <div className="divnav_right">
          <a href="/articles/article_edit" className="link_edit">
            <img src="/icons/pencil-plus-outline.png" alt='search' className="headericon_invert" />
          </a>
          <UserHeaderMenu/>
        </div>

      </div>
    </div>
    //----</ return: render >----
  );
  //----</ HTML-Render >----
    //============</ Component >============
  }
