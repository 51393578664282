import React, { useState,useEffect } from 'react'
import { useCookies } from 'react-cookie';  //auth_token, xsrf
import { useSearchParams } from 'react-router-dom';

// Project
import { encrypt_word} from "../../../helpers/encrypt.ts"
import { get_tempkey } from "../../../helpers/authentication.ts"
import { check_password } from "../../../helpers/check_input_validations.ts"
import "./user_confirm_password_reset.css"

export default function UserConfirmPasswordReset() {
  //============< Component >============

  //< variables >
  const api_url = process.env.REACT_APP_API_URL;
  
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmWord, setConfirmWord] = useState('');
  const [confirmWordError, setConfirmWordError] = useState('');
  const [key, setKey] = useState('');
  const [iv, setIV] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  const [isDone, setIsDone] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['XSRF-TOKEN']);

  //get id from url-parameter : read?id=123
  const [searchParams] = useSearchParams();
  const reset_token = searchParams.get('rt'); //reset-token
  //</ variables >


  //const navigate = useNavigate()

  //--< init >--
  useEffect(() => {init();}, []);
  useEffect(() => {check_form_inputs();}, [password,confirmWord]);

  async function init(){
    document.title="🔑 User Confirm Password Reset";
    console.log("antiforgery=" + cookies['XSRF-TOKEN']);
    init_temp_key();
  }
  //--</ init >--
  
  async function init_temp_key(){
    //--------< init_temp_key() >--------
    setError("");
    const server_key_iv = await get_tempkey(); //from authorization service
    const server_key = server_key_iv.substring(0,32);
    const server_iv = server_key_iv.substring(32);
    if( server_key==="" || server_iv ===""){
      setError("No Transmittion Keys available") ;
      setKey("");
      setIV("");
    }
    else
    {
      setKey(server_key);
      setIV(server_iv);
    }  
    //--------</ init_temp_key() >--------
  }

  const onSend = () => {
    //--------< onSend Button-Event() >--------
    //< check >
    let ok_form_inputs = check_form_inputs();
    if (ok_form_inputs === false) return;
    //</ check >

    //--< send to register >--

    //clear cookies
    removeCookie('un',{path:'/'});
    removeCookie('ut',{path:'/'});  
    
    //* finally send it to server if everything ok  
    // Authentication calls will be made here...
    confirm_password_reset()
    //--</ send to register >--
    //--------</ onSend Button-Event() >--------
  }

  async function confirm_password_reset(){
    //--------< confirm_password_reset() >--------
    if(key==="" || iv===""){  setError("No Authorization keys available") ;
      return ;
    }
    
    let encrypted_password= await encrypt_word(password, key, iv);
    
    //--< prepare send >--
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${reset_token}`, //*enthält userguid u
       },
      body: JSON.stringify(
          {
            up: encrypted_password,
          })
    };

    const url= api_url + '/user/user_confirm_password_reset';
    var data;
    try {
      const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
      if (response?.ok) {
          
          //Navigate to Startpage 
          //window.location.href="/";//"/user/user_login";  
          setIsDone(true);
          //--< OK >--
        }
        else if (response.status===401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status===400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  //--------</ confirm_password_reset() >--------
  }

  function check_form_inputs():boolean{
    //--------< check_form_inputs() >--------
    // Set initial error values to empty
    setPasswordError('')
    setConfirmWordError('')
    setError("");
    
    let password_Check=check_password(password);
    if ( password_Check != "" )  {
      setPasswordError(password_Check)
      return false;
    }

    if ( confirmWord != password )  {
      setConfirmWordError('Bestätigungswort ist ungleich Passwort')
      return false;
    }

    //alles ok-> true
    return true;
    //--------</ check_form_inputs() >--------
    
  }

  


  
  if (isDone===true) {    
      return(
      <div className="card div_message">Das neue Passwort wurde erfolgreich gespeichert:
        <div>Sie können sich jetzt hier mit den neuen Daten anmelden.</div>      
        <div className="div_goto_login">Goto Login: <a href="/user/user_login" className="a_link"><img src="/icons/login.png" alt='login' className="userheadermenu_icon_menuitem"/> login</a></div>        
      </div>
      )
  }

  return (
    <div className="div_form">
      {error && <div style={{ color: 'red' }}>ERROR: {error}</div> }

      {isLoading && <div >loading..</div> }

     
      <div className="divinput">
        <div className="divtitle">
            <img src="/icons/form-textbox-password.png" alt='login' className="titleicon"/> 
            Neues Password eingeben:
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/key.png" alt='input icon' className="input_icon"/>   
            <input
              id="input_password"     
              type="password"
              placeholder="Enter your NEW Password here"
              onInput={(input_event)=>setPassword(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{passwordError}</label>
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/check-all.png" alt='input icon' className="input_icon"/> 
            <input     
              id="input_confirmword"     
              type="password"
              placeholder="Confirm your NEW Password here"
              onInput={(input_event)=>setConfirmWord(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{confirmWordError}</label>
        </div>

        
        <div className="div_sendbutton">
          <input className="btn btn-primary button_login" type="button" onClick={onSend} value={'Set New Password'} />
        </div>
      </div> 
      
      <div className="div_nav_links">
        <a href="/user/user_login" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='register' className="img_navlink"/> login</a>
        <a href="/user/user_register" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='register' className="img_navlink"/> Register</a>        
      </div>

    </div>
  )
}

