import {  Routes, Route } from'react-router-dom';

//*Project Components
import './App.css';

import HomeComponent  from './components/home/home';
import HeadbarComponent  from './components/headbar/headbar/headbar';
import ArticlesListComponent from './components/articles/articles_list/articles_list';
import ArticleReadComponent from './components/articles/article_read/article_read';
import ArticleEditComponent from './components/articles/article_edit/article_edit';
import UserLogin  from './components/user/user_login/user_login';
import UserRegister  from './components/user/user_register/user_register';
import UserSendPasswordReset  from './components/user/user_send_password_reset/user_send_password_reset';
import UserConfirmPasswordReset  from './components/user/user_confirm_password_reset/user_confirm_password_reset';
import UserConfirmNewUser  from './components/user/user_confirm_new_user/user_confirm_new_user';
import UserAccount  from './components/user/user_account/user_account';
import UserChangeEmail  from './components/user/user_change_email/user_change_email';
import UserChangePassword  from './components/user/user_change_password/user_change_password';
import GoogleAds_Footer  from './components/ads/google_ads_footer';
import NoPage from "./pages/NoPage";



function App() {
  
  return (
    <div className="divapp">   
      
      <div id="divNavbar" className="divnavbar">
      <HeadbarComponent/>
      </div >
      
      <div id="divContent" className="divcontent">  
      
        <Routes>
          <Route path="/" element={<ArticlesListComponent />}/>
          <Route path="/articles/articles_list" element={<ArticlesListComponent />} />
          <Route exact path="/articles/article_read/*" element={<ArticleReadComponent />} />  {'/* comment: read?id=1 */'}
          <Route exact path="/articles/article_edit/*" element={<ArticleEditComponent />} />  {'/* comment: edit?id=1 * mit authorize/'}
          <Route exact path="/user/user_login/*" element={<UserLogin />} />
          <Route exact path="/user/user_register/*" element={<UserRegister />} />
          <Route exact path="/user/user_send_password_reset/*" element={<UserSendPasswordReset />} />
          <Route exact path="/user/user_confirm_password_reset/*" element={<UserConfirmPasswordReset />} />
          <Route exact path="/user/user_confirm_new_user/*" element={<UserConfirmNewUser />} />
          <Route exact path="/user/user_account/*" element={<UserAccount />} />
          <Route exact path="/user/user_change_email/*" element={<UserChangeEmail />} />
          <Route exact path="/user/user_change_password/*" element={<UserChangePassword />} />
          
          <Route path="*" element={<NoPage/>} />
        </Routes>
      
      </div>
      <footer >
        <GoogleAds_Footer />
        <div><a href="https://www.freelancermap.de/profil/raimund-popp-250581">Contakt: Freiberuflicher Softwareentwickler React C# Asp.Net WebApi Azure</a></div>
      </footer>
    </div>
  );
}

export default App;
