import * as CryptoJS from "crypto-js";

export async function encrypt_word(word_to_encrypt:string, key:string="", iv:string=""):string{
    
    //------< encrypt_word >-------

    //< check >
    if( word_to_encrypt===""){ return ""} //check
    if( key===""){ return ""} //check
    if( iv===""){ return ""} //check
    
    const key_bytes = CryptoJS.enc.Utf8.parse(key); // 32 Zeichen für AES-256
    const iv_bytes = CryptoJS.enc.Utf8.parse(iv); // 16 Bytes für IV

    //</ check >
    // Verschlüsseln der E-Mail und des Passworts
    const encryptedString = CryptoJS.AES.encrypt(word_to_encrypt, key_bytes, {
      iv: iv_bytes,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }).toString();

    //output
    return encryptedString;
    //------</ encrypt_word >-------
  }