import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';  //auth_token

// Project
import { encrypt_word } from "../../../helpers/encrypt.ts"
import { get_tempkey } from "../../../helpers/authentication.ts"
import { check_password,check_confirmword } from "../../../helpers/check_input_validations.ts"
import "./user_change_password.css"

export default function UserChangePassword() {
  //============< Component >============
  const api_url = process.env.REACT_APP_API_URL;

  //< variables >
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['un','ut','XSRF-TOKEN']);
  const [key, setKey] = useState('');
  const [iv, setIV] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmWord, setConfirmWord] = useState('');
  const [confirmWordError, setConfirmWordError] = useState(''); 
  //</ variables >


  //--< init >--
  useEffect(() => {init();}, []);
  async function init(){
    document.title="🔑 Change Password";
    init_temp_key();    
  }
  useEffect(() => {check_form_inputs();}, [password,confirmWord]);
  //--</ init >--
  
  function check_form_inputs():boolean{
    //--------< check_form_inputs() >--------
    // Set initial error values to empty
    setPasswordError('')
    setConfirmWordError('')
    
    var checked_result=true;
    var check=false;
    // Check if the user has entered both fields correctly
    check=check_password(password);
    if ( check != "" )  {
      setPasswordError(check)
      checked_result= false;
    }
    check=check_confirmword(password,confirmWord);
    if ( check != "" )  {
      setConfirmWordError(check)
      checked_result= false;
    }


    //alles ok-> true
    return checked_result;
    //--------</ check_form_inputs() >--------
    
  }


  async function init_temp_key(){
    //--------< init_temp_key() >--------
    setError("");
    const server_key_iv = await get_tempkey(); //from authorization service
    const server_key = server_key_iv.substring(0,32);
    const server_iv = server_key_iv.substring(32);
    if( server_key=="" || server_iv ==""){
      setError("No Transmittion Keys available") ;
      setKey("");
      setIV("");
    }
    else
    {
      setKey(server_key);
      setIV(server_iv);
    }  
    //--------</ init_temp_key() >--------
  }


  const onSend = () => {
    //--------< onSend Button-Event() >--------
  
    //< check >
    let ok_form_inputs = check_form_inputs();
    if (ok_form_inputs == false) return;
    //</ check >
    
    send_to_server()
    //--------</ onSend Button-Event() >--------
  }


  

  async function send_to_server(){
    //--------< send_to_server() >--------
    //let password = document.getElementById('input_passwort').value;
    
    //< init >
    if(key=="" || iv==""){  setError("No Authorization keys available") ;
      return ;
    }

    let encrypted_password= await encrypt_word(password, key, iv);

    //</ init >
    const auth_token = cookies['ut'];
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' ,
        'Authorization': `Bearer ${auth_token}`, //*enthält userguid u
      },
      body: JSON.stringify(
          {
            up: encrypted_password,            
          })
    };

    const url= api_url + '/user/user_change_password_set';
    var data;
    try {
      const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
      if (response?.ok) {
          //--< OK >--  
          setIsLoading(false);
          setIsDone(true) 
          //--< OK >--
        }
        else if (response.status==401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status==400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  //--------</ send_to_server() >--------
  }






 
  return (
    <div className="div_form">
      { error && <div style={{ color: 'red' }}>ERROR: {error}</div> }

      { isLoading && <div >loading..</div> }
      { isDone && (  
          <div style={{ color: 'green' }}>
          <div> Das neue Passwort wurde gespeichert </div>      </div> 
        )
      }

     
      <div className="divinput">
        <div className="divtitle">
            <img src="/icons/form-textbox-password.png" alt='Account Data' className="titleicon"/> 
            Change Password
        </div>
        
        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/form-textbox-password.png" alt='input icon' className="input_icon"/> 
            <input
              id="input_password"
              type="password"
              placeholder="Enter your NEW Password here"
              onInput={(input_event)=>setPassword(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{passwordError}</label>
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/check-all.png" alt='input icon' className="input_icon"/> 
            <input          
              type="password"
              placeholder="Confirm your input here"
              onInput={(input_event)=>setConfirmWord(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{confirmWordError}</label>
        </div>

        <div className="div_sendbutton">
          <input className="btn btn-primary button_login" type="button" onClick={onSend} value={'Speichern'} />
        </div>
      </div> 
      

    </div>
  )
}

