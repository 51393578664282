import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Project
import { encrypt_word } from "../../../helpers/encrypt.ts"
import { get_tempkey } from "../../../helpers/authentication.ts"
import { check_username,check_email,check_password,check_confirmword } from "../../../helpers/check_input_validations.ts"
import "./user_register.css"

export default function UserRegister() {
  //============< Component >============
  
  //< variables >
  const api_url = process.env.REACT_APP_API_URL;
  
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmWord, setConfirmWord] = useState('');

  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmWordError, setConfirmWordError] = useState('');
  
  const [key, setKey] = useState('');
  const [iv, setIV] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  const [isDone, setIsDone] = useState('');
  //</ variables >

  //const navigate = useNavigate()

  //--< init >--
  useEffect(() => {init();}, []);
  useEffect(() => {check_form_inputs();}, [username,email,password,confirmWord]);


  async function init(){
    document.title="🔑 Register As New User";
    init_temp_key();
  }
  //--</ init >--

  const onSend = () => {
    //--------< login() >--------
    //< check >
    let ok_form_inputs = check_form_inputs();
    if (ok_form_inputs == false) return;
    //</ check >

    //--< send to register >--
    //* finally send it to server if everything ok  
    // Authentication calls will be made here...
    register_on_server()
    //--</ send to register >--
    //--------</ login() >--------
  }

  
  function check_form_inputs():boolean{
    //--------< check_form_inputs() >--------
    // Set initial error values to empty
    setUsernameError('')
    setEmailError('')
    setPasswordError('')
    setConfirmWordError('')
    setError("");
    
    var checked_result=true;
    var check=false;
    // Check if the user has entered both fields correctly
    check=check_username(username);
    if ( check != "" )  {
      setUsernameError(check)
      checked_result= false;
    }
    check=check_email(email);
    if ( check != "" )  {
      setEmailError(check)
      checked_result= false;
    }
    check=check_password(password);
    if ( check != "" )  {
      setPasswordError(check)
      checked_result= false;
    }
    check=check_confirmword(password,confirmWord);
    if ( check != "" )  {
      setConfirmWordError(check)
      checked_result= false;
    }

 
    //alles ok-> true
    return checked_result;
    //--------</ check_form_inputs() >--------
    
  }


  async function init_temp_key(){
    //--------< init_temp_key() >--------
    setError("");
    const server_key_iv = await get_tempkey(); //from authorization service
    const server_key = server_key_iv.substring(0,32);
    const server_iv = server_key_iv.substring(32);
    if( server_key=="" || server_iv ==""){
      setError("No Transmittion Keys available") ;
      setKey("");
      setIV("");
    }
    else
    {
      setKey(server_key);
      setIV(server_iv);
    }  
    //--------</ init_temp_key() >--------
  }


  async function register_on_server(){
    //--------< register_on_server() >--------
    if(key=="" || iv==""){  setError("No Authorization keys available") ;
      return ;
    }

    let encrypted_email= await encrypt_word(email, key, iv);
    let encrypted_password= await encrypt_word(password, key, iv);
    let encrypted_username= await encrypt_word(username, key, iv);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
          {
            un: encrypted_username,  
            ue: encrypted_email,
            up: encrypted_password,              
          })
    };

    const url= api_url + '/user/user_register';
    var data;
    try {
      const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
      if (response?.ok) {
          //--< OK >--  
          setIsLoading(false);
          //data = await response.json(); //*convert json to data
          //var responseText=await response.text();
          setIsDone(true) //text aus .net api badrequest;    
          //--< OK >--
        }
        else if (response.status==401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status==400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  //--------</ register_on_server() >--------
  }



  if (isLoading) {    return <div style={{ color: 'black' }}>Loading...</div>; }
  if (isDone) {    return (
    <div className="card div_message">
      <img src="/icons/email-fast.png" alt='input icon' className="input_icon"/> 
      
    <b style={{ color: 'green' }}>Es wurde eine Email zur Bestätigung gesendet.</b>
    <br />Die Bestätigung ist innerhalb eines Tages möglich.
    <br />Falls die Email nicht ankommt, prüfen Sie bitte den SPAM Ordner.
    <br />
    <br />
    </div>
    
  )
    }
  
  return (
    //======< HTML-Render >======
  
    <div className="div_form">

      {error && <div style={{ color: 'red' }}>ERROR: {error}</div> }
  
      <div className="divinput">

        <div className="divtitle">
            <img src="/icons/account-key.png" alt='register' className="titleicon"/> Register As New User
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/account-outline.png" alt='input icon' className="input_icon"/> 
            <input
              placeholder="Enter your displayed Username here"
              onChange={(input_event) => setUsername(input_event.target.value)}
              className="inputfield"
            />
          </div>
          <label className="errorLabel">{usernameError}</label>
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/email-outline.png" alt='input icon' className="input_icon"/> 
            <input
              placeholder="Enter your email here"
              onInput={(input_event)=>setEmail(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{emailError}</label>
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/key.png" alt='input icon' className="input_icon"/>   
            <input
              type="password"
              placeholder="Enter your password here"
              onInput={(input_event)=>setPassword(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{passwordError}</label>
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/check-all.png" alt='input icon' className="input_icon"/> 
            <input          
              type="password"
              placeholder="Confirm your password here"
              onInput={(input_event)=>setConfirmWord(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{confirmWordError}</label>
        </div>

          <input className="btn btn-primary button_register" type="button" onClick={onSend} value="Register" />
        
      </div>

      <div className="div_nav_links">
        <a href="/user/user_login" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='register' className="img_navlink"/> login</a>
        <a href="/user/user_password_reset" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='reset' className="img_navlink"/> Passwort reset</a>
      </div>
    </div>
    //======</ HTML-Render >======
  )
  //============</ Component >============
}

