import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';  //auth_token

// Project
import { encrypt_word} from "../../../helpers/encrypt.ts"
import { get_tempkey } from "../../../helpers/authentication.ts"
import "./user_login.css"

export default function UserLogin() {
  //============< Component >============

  //< variables >
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [key, setKey] = useState('');
  const [iv, setIV] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  const [loggedIn, setLoggedIn] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['un','ut','XSRF-TOKEN']);
  
  const api_url = process.env.REACT_APP_API_URL;
  //</ variables >


  //const navigate = useNavigate()

  //--< init >--
  useEffect(() => {init();}, []);
  async function init(){
    document.title="🔑 User Login";
    console.log("username=" + cookies['un']);//ok
    console.log("auth_token=" + cookies.ut); //auch ok
    console.log("antiforgery=" + cookies['XSRF-TOKEN']);
    init_temp_key();
  }
  //--</ init >--
  
  async function init_temp_key(){
    //--------< init_temp_key() >--------
    setError("");
    const server_key_iv = await get_tempkey(); //from authorization service
    const server_key = server_key_iv.substring(0,32);
    const server_iv = server_key_iv.substring(32);
    if( server_key=="" || server_iv ==""){
      setError("No Transmittion Keys available") ;
      setKey("");
      setIV("");
    }
    else
    {
      setKey(server_key);
      setIV(server_iv);
    }  
    //--------</ init_temp_key() >--------
  }

  const onSend = () => {
    //--------< onSend Button-Event() >--------
  
    //< check >
    let ok_form_inputs = check_form_inputs();
    if (ok_form_inputs == false) return;
    //</ check >

    //clear cookies
    removeCookie('un',{path:'/'});
    removeCookie('ut',{path:'/'});  
    
    //--< send to register >--
    //* finally send it to server if everything ok  
    // Authentication calls will be made here...
    login_on_server()
    //--</ send to register >--
    //--------</ onSend Button-Event() >--------
  }

  async function login_on_server(){
    //--------< login_on_server() >--------
    if(key=="" || iv==""){  setError("No Authorization keys available") ;
      return ;
    }

    let encrypted_email= await encrypt_word(email, key, iv);
    let encrypted_password= await encrypt_word(password, key, iv);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
          {
            ue: encrypted_email,
            up: encrypted_password,              
          })
    };

    const url= api_url + '/user/user_login';
    var data;
    try {
      const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
      if (response?.ok) {
          //--< OK >--  
          setIsLoading(false);
          setLoggedIn(await "Logged In") //text aus .net api badrequest;    
          
          data = await response.json(); //*convert json to data
          const auth_token=data.ut;
          const username=data.un;
          //const expiresDateTime= new Date().getDate() + 30
          //const maxAge_Seconds=1*60*24*30 ; //30 Tage
          //2592000=60Sekunden*60Minuten*24Stunden*30Tage
          setCookie("un", username, { path: "/", maxAge: 2592000}); // setting the cookie, 30 days
          setCookie("ut", auth_token, { path: "/" ,maxAge: 2592000}); // setting the cookie
          //   expires: new Date(getDataFromToken(tokenFromURL)['exp'] * 1000),
          
          //Navigate to Startpage 
          window.location.href="/";//"/user/user_login";  
          //--< OK >--
        }
        else if (response.status==401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status==400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  //--------</ register_on_server() >--------
  }

  function check_form_inputs():boolean{
    //--------< check_form_inputs() >--------
    // Set initial error values to empty
    setEmailError('')
    setPasswordError('')
    setError("");
    
    // Check if the user has entered both fields correctly
    if ('' === email) {
      setEmailError('Please enter your email')
      return false;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email')
      return false;
    }

    if ('' === password) {
      setPasswordError('Please enter a password')
      return false;
    }

    if (password.length < 7) {
      setPasswordError('The password must be 8 characters or longer')
      return false;
    }

    //alles ok-> true
    return true;
    //--------</ check_form_inputs() >--------
    
  }




  if (loggedIn) {    return <div style={{ color: 'green' }}>
  <div> Du bist jetzt angemeldet </div>      </div>; 
  }

  return (
    <div className="div_form">
      {error && <div style={{ color: 'red' }}>ERROR: {error}</div> }

      {isLoading && <div >loading..</div> }

     
      <div className="divinput">
        <div className="divtitle">
            <img src="/icons/login.png" alt='login' className="titleicon"/> 
            Login As Existing User
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/email-outline.png" alt='input icon' className="input_icon"/> 
            <input
              placeholder="Enter your email here"
              onChange={(input_event) => setEmail(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{emailError}</label>
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <img src="/icons/key.png" alt='input icon' className="input_icon"/>   
            <input
              type="password"
              placeholder="Enter your password here"
              onChange={(input_event) => setPassword(input_event.target.value)}
              className="inputfield"
            />          
          </div>
          <label className="errorLabel">{passwordError}</label>
        </div>

        <div className="div_sendbutton">
          <input className="btn btn-primary button_login" type="button" onClick={onSend} value={'Log in'} />
        </div>
      </div> 
      
      <div className="div_nav_links">
        <a href="/user/user_register" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='register' className="img_navlink"/> Register</a>
        <a href="/user/user_send_password_reset" className="a_navlink"> <img src="/icons/arrow-right-thin.png" alt='reset' className="img_navlink"/> Passwort reset</a>
      </div>

    </div>
  )
}

