import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';  //auth_token

// Project
import "./user_account.css"

export default function UserAccount() {
  //============< Component >============

  //< variables >
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['un','ut','XSRF-TOKEN']);

  const api_url = process.env.REACT_APP_API_URL;
  //</ variables >


  //const navigate = useNavigate()

  //--< init >--
  useEffect(() => {init();}, []);
  async function init(){
    document.title="🎫 User Account";
    get_data_from_to_server();
  }
  //--</ init >--
  

  const onSend = () => {
    //--------< onSend Button-Event() >--------
  
    //< check >
    let ok_form_inputs = check_form_inputs();
    if (ok_form_inputs == false) return;
    //</ check >
    
    send_to_server()
    //--------</ onSend Button-Event() >--------
  }

  async function get_data_from_to_server(){
    //--------< get_data_from_to_server() >--------
    const auth_token = cookies['ut'];
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json' ,
        'Authorization': `Bearer ${auth_token}`, //*enthält userguid u
      }
    };

    const url=  api_url + '/user/user_account_get';
    var data;
    try {
      const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
      if (response?.ok) {
          //--< OK >--  
          setIsLoading(false);
          
          //< set_data >
          const apidata = await response.json();  //*convert json to data
          document.getElementById('input_firstname').defaultValue=apidata.firstname;
          document.getElementById('input_lastname').defaultValue= apidata.lastname;
          document.getElementById('input_phonenumber').defaultValue= apidata.phonenumber;
          //</ set_data >
          //--< OK >--
        }
        else if (response.status==401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status==400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  //--------</ get_data_from_to_server() >--------
  }

  async function send_to_server(){
    //--------< send_to_server() >--------
    //< init >
    let firstname = document.getElementById('input_firstname').value;
    let lastname = document.getElementById('input_lastname').value;
    let phonenumber = document.getElementById('input_phonenumber').value;
    //</ init >
    const auth_token = cookies['ut'];
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json' ,
        'Authorization': `Bearer ${auth_token}`, //*enthält userguid u
      },
      body: JSON.stringify(
          {
            firstname: firstname,
            lastname: lastname,
            phonenumber:phonenumber,
          })
    };

    const url= api_url + '/user/user_account_set';
    var data;
    try {
      const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
      if (response?.ok) {
          //--< OK >--  
          setIsLoading(false);
          setIsDone(true) 
          //--< OK >--
        }
        else if (response.status==401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status==400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            let fehlermeldung= "status=" + response.status + " " + response.statusText;//response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error.toString());        
        }
      finally {
        setIsLoading(false);
      }
  //--------</ send_to_server() >--------
  }

  function check_form_inputs():boolean{
    //--------< check_form_inputs() >--------
    // Set initial error values to empty
    setError("");
    
    //#TODO
    //alles ok-> true
    return true;
    //--------</ check_form_inputs() >--------
    
  }




 
  return (
    <div className="div_form">
      { error && <div style={{ color: 'red' }}>ERROR: {error}</div> }

      { isLoading && <div >loading..</div> }
      { isDone && (  
          <div style={{ color: 'green' }}>
          <div> Daten wurden gespeichert </div>      </div> 
        )
      }

     
      <div className="divinput">
        <div className="divtitle">
            <img src="/icons/card-account-details.png" alt='Account Data' className="titleicon"/> 
            User Daten
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <input
              id="input_firstname"
              placeholder="Firtsname"
              className="inputfield"
            />          
          </div>          
        </div>

        <div className="div_input_group">
          <div className="div_inputfield">
            <input
              id="input_lastname"
              placeholder="Lastname"
              className="inputfield"
            />          
          </div>          
        </div>
        <div className="div_input_group">
          <div className="div_inputfield">
            <input
              id="input_phonenumber"
              placeholder="Phone Number"
              className="inputfield"
            />          
          </div>          
        </div>

        <div className="div_sendbutton">
          <input className="btn btn-primary button_login" type="button" onClick={onSend} value={'Speichern'} />
        </div>
      </div> 
      

    </div>
  )
}

