export function  replace_link_to_fileserver(innerHTML:string):string{
    const files_url=process.env.REACT_APP_FILES_URL;

    //--------< fileserver anpassen >--------
    if(innerHTML === null) return "";
    if(innerHTML === "") return "";
    //src="/Daten/Images/3/Image_2733_1.jpg" zu 
    //https://localhost:7002/files/user_files/Daten/Images/3/Image_2733_1.jpg    
    var pos_src= innerHTML.indexOf("src=");
    while (pos_src>0) {
      if(pos_src>0){
        var pos_start=innerHTML.indexOf("\"",pos_src+4);
        if(pos_start>=0){
          var pos_end=innerHTML.indexOf("\"",pos_start+1);
          if(pos_end>=0){
            var innerlink=innerHTML.substring(pos_start+1,pos_end);
            if(innerlink.startsWith("Daten")){
              var newurl= files_url + "/files/user_files/" + innerlink ;
              var leftpart=innerHTML.substring(0,pos_start+1);
              var rightpart=innerHTML.substring(pos_end);
              innerHTML=leftpart + newurl + rightpart;
            }
            else if(innerlink.startsWith("/Daten")){
              var newurl2= files_url + "/files/user_files" + innerlink ;
              var leftpart2=innerHTML.substring(0,pos_start);
              var rightpart2=innerHTML.substring(pos_end+1);
              innerHTML=leftpart2 + newurl2 + rightpart2;
            }
          } 
        }        
      }

      pos_src=innerHTML.indexOf("src=",pos_src+1); //next
    }
    return innerHTML;
    //--------</ fileserver anpassen >--------
  }