import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';  //auth_token
import { Fab } from '@mui/material';  //edit button
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


// Project
import "./article_edit.css"
import {replace_link_to_fileserver} from "../../../helpers/texthelpers.ts";



export default function ArticleEditComponent() {
  //============< Component >============
  //get id from url-parameter : read?id=123
  const [searchParams] = useSearchParams();
  let guidArticle = searchParams.get('i');
  //--</ Variables >--

  const api_url=process.env.REACT_APP_API_URL;
  
  //--< Variables >--
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const [error, setError] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(['ut','XSRF-TOKEN']);
  //--</ Variables >--

  //--< Init: Hooks >--
  useEffect(() => {fetchData();}, []); // Pass an empty array to only call the function once on mount.
  useEffect(() => {init();}, []);

  function init(){
    const auth_token = cookies.ut;    
    if(auth_token){setIsLoggedIn(true)}
    // console.log("auth_token=" + cookies.auth_token);
    // console.log("antiforgery=" + cookies['XSRF-TOKEN']);
  }
  
  //--</ Init: Hooks >--


  //====< functions >====
  //*load data from web api
  async function fetchData() {
    //------------< fetchData() >------------
    setIsLoading(true);

    //let xsrfToken = this.getCookie('XSRF-TOKEN');   //*get AntiforgeryToken from Cookies
    //if (xsrfToken == null) { alert("AntiforgeryToken is missing")};
    let xsrfToken = cookies['XSRF-TOKEN'];   //*get AntiforgeryToken from Cookies
    //</ get text >

    //--< prepare send >--
    const auth_token = cookies['ut'];
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`, //*enthält userguid u
            'X-XSRF-TOKEN': xsrfToken       //*add antiforgerytoken in from Cookies into Header               
        }
    };
    let url= api_url +'/articles/get_article_edit/' ;
    if(guidArticle) { url=url+guidArticle;} else { url = url + "new"}

    var data;
    try {
        const response = await fetch(url,requestOptions );   //*get web_data from web api as json-string
    
        if (response?.ok) {
            data = await response.json(); //*convert json to data

            //< alten Bild-pfad tauschen >
            let content_Html=data.content_Html;
            if(content_Html !=""){
              let correct_ImageHtml=replace_link_to_fileserver(content_Html);
              data.content_Html=correct_ImageHtml;
            }
            //</ alten Bild-pfad tauschen >

            //< browser title >
            let content_Title=data.content_Title;
            document.title= "✏" + content_Title
            //< browser title >

            setItem(data);
        }
        else if (response.status==401) {
          setError("Nicht angemeldet. Bitte erst anmelden mit login (401)");            
        }
        else if (response.status==400) {
          setError(await response.text()) //text aus .net api badrequest;            
        }
        else {
            //-< fehler >-
            //let problem = await response.text();  //*Zeigt Text aus Badrequest(text)
            //setError(problem);     
            let fehlermeldung= response.toString();
            setError(fehlermeldung);
            //-</ fehler >-
        }
      }
     catch (error) {
        setError(error);        
        }
      finally {
        setIsLoading(false);
      }
      //------------</ fetchData() >------------
    }

    async function onSave() {
      //------------< onSave() >------------
      //1: save
      await send_Data_to_Api();
      //2: Navigate to Startpage 
      window.location.href="/articles/article_read?i=" + guidArticle;
      //------------</ onSave() >------------
    }

    async function onDelete() {
      //------------< onDelete() >------------
      //1: save
      await delete_Article();
      //2: Navigate to Startpage 
      window.location.href="/articles/articles_list";
      //------------</ onDelete() >------------
    }

    async function send_Data_to_Api() {
      //------< send_Data_to_Api() >------

      //< init >
      let input_content_html = document.getElementById('input_content_html');
      let content_Html = input_content_html.innerHTML;
      let content_Text = input_content_html.innerText;
      let input_content_title = document.getElementById('input_content_title');
      let content_title = input_content_title.value;
      let input_keywords = document.getElementById('input_keywords');
      let keywords = input_keywords.value;
      let input_folders = document.getElementById('input_folders');
      let folders = input_folders.value;
      let guidUser=item.guidUser;
      //</ init >

      //--< prepare send >--
      setIsLoading(true);
      try {
        const auth_token = cookies['ut'];
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth_token}`, //*enthält userguid u
              // 'X-XSRF-TOKEN': xsrfToken       //*add antiforgerytoken in from Cookies into Header               
            },
            body: JSON.stringify(
                {
                    //< data to send >
                    guidArticle: guidArticle,
                    guidUser: guidUser,
                    content_Title: content_title,
                    content_Html: content_Html,
                    content_Text: content_Text,
                    keywords: keywords,
                    folders: folders,
                    // guiduser über ut
                    //</ data to send >
                })
        };
        //--</ prepare send >--

        //< send >
        const url= api_url + '/articles/save_article';
        const response = await fetch(url, requestOptions); //*SEND DATA
        //</ send >
        if (response.status !== 200) {
            //-< error: sending >-
            if (response.status === 400) setError("Bad Data Request");
            else if (response.status === 403) setError("User is not Owner");
            else setError('send error');
            //-</ error: sending >-
        }
        else {
          setIsLoading(false);
           
          guidArticle = await response.text(); //*convert json to data

        }
      } catch (error) {
        setError(error);
     } finally {
        setIsLoading(false);
     }
        //------</ send_Data_to_Api() >------
    }

    async function delete_Article() {
      //------< delete_Article() >------
      //--< prepare send >--
      setIsLoading(true);
      try {
        const auth_token = cookies['ut'];
        const requestOptions = {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth_token}`, //*enthält userguid u
              // 'X-XSRF-TOKEN': xsrfToken       //*add antiforgerytoken in from Cookies into Header               
            }
            
        };
        //--</ prepare send >--

        //< send >
        const url= api_url + '/articles/delete_article?guidarticle=' + guidArticle;
        const response = await fetch(url, requestOptions); //*SEND DATA
        //</ send >
        if (response.status !== 200) {
            //-< error: sending >-
            if (response.status === 400) setError("Bad Data Request");
            else if (response.status === 403) setError("User is not Owner");
            else setError('send error');
            //-</ error: sending >-
        }
        else {
          setIsLoading(false);
           
          //guidArticle = await response.text(); //*convert json to data

        }
      } catch (error) {
        setError(error);
     } finally {
        setIsLoading(false);
     }
        //------</ send_Data_to_Api() >------
    }
  //====</ functions >====

  
  //============< HTML-Output-Render >============
  if (isLoading) {    return <div style={{ color: 'black' }}>Loading...</div>; }
  if (error) {    return <div style={{ color: 'red' }}>ERROR: {error}</div>; }
  if (!isLoggedIn) {    
    return (
      <div className="card div_message">
        <div style={{ color: 'red' }}>Your not logged in</div>
        <div className="div_navlinks">
            <a href="/user/user_login" className="a_message_link">
                <img src="/icons/login.png" alt='login' className="userheadermenu_icon_menuitem"/> login
            </a>
        </div>
        <div >
            <a href="/user/user_register" className="a_message_link">
                <img src="/icons/account-key.png" alt='register' className="userheadermenu_icon_menuitem"/> register
            </a>
        </div>
      </div>
  ); 
}

  if (error) {    return <div style={{ color: 'red' }}>ERROR: {error}</div>; }

  if (item) {
    return (      
      <div  className="div_arcticle_edit_component">              
        <div className="divgroup_toprow">
          <img src="/icons/pencil.png" alt='keywords' className="iconshortlinks"/>
          <h5> EDIT </h5>
          <div className="div_right_buttons">
            <a onClick={onDelete}>
            <img src="/icons/trash-can-outline.png" alt='delete' className="iconshortlinks"/>
            </a>
            <div className="divsavebutton">
                <Fab color="secondary" aria-label="save" style={{backgroundColor:"green"}} onClick={onSave}>
                  <CheckCircleIcon />
                </Fab>            
            </div>
          </div>
        </div>

        <div id="divshortlinks" className="divshortlinks">
          <div id="divkeywords" className="divkeywords" >
            <img src="/icons/text-search.png" alt='keywords' className="iconshortlinks"/>    
            <input id="input_keywords" defaultValue={item.keywords} className="form-control" placeholder="Enter keywords like: c#;react;javascript;developer"></input>
          </div> 
                        
          <div id="divfolders" className="divfolders">
            <img src="/icons/layers-search.png" alt='folders;folder' className="iconshortlinks"/>
            <input id="input_folders" defaultValue={item.folders} className="form-control input_shortlinks" placeholder="Enter folders like: html/css;html/codes"></input>            
          </div>

          
        </div>

        <div  className="divgroup_title">
          <img src="/icons/format-text-rotation-none.png" alt='keywords' className="iconshortlinks"/>    
          <input id="input_content_title" defaultValue={item.content_Title} className="form-control input_title" placeholder="Enter title here.." />
        </div>

        
        <div  className="divgroup_text">
          <img src="/icons/file-document-edit-outline.png" alt='keywords' className="iconshortlinks"/>    
          <div id="input_content_html" className="form-control input_html"
            dangerouslySetInnerHTML={{ __html: item.content_Html }}
            contentEditable={true} suppressContentEditableWarning={true}
          />
        </div>

      </div>          

    //============</ HTML-Output-Render >============
    )
  }
  //============</ Component >============
}


