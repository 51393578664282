import React, { useEffect, useState } from 'react';
import config from "../../config.json"
import './google_ads_side.css';


export function GoogleAds_Side250() { 
    useEffect(()=>{
    //----< init() >----
     (window.adsbygoogle = window.adsbygoogle || []).push({});
      //----</ init() >----
    },[])
  //----< HTML-Render >----
  return (
    //----< return: render >----
    //*datat-ad-client={config.google_ad_client}      
    <div className="div_google_ads_side_250">
      
      <ins className="adsbygoogle"
      data-ad-client="ca-pub-7755842634690101"
      data-ad-slot="4863940522"   
      style={{display: "inline-block",height:600,width:300}}   
      ></ins> 
      
    </div>

    //----</ return: render >----
  );
  //----</ HTML-Render >----
    //============</ Component >============
  }

  export  function GoogleAds_Side600() { 
    useEffect(()=>{
      //----< init() >----
       (window.adsbygoogle = window.adsbygoogle || []).push({});
        //----</ init() >----
      },[])

    //----< HTML-Render >----
    return (
      //----< return: render >----
      <div className="div_google_ads_side_600">
        <ins className="adsbygoogle"
        data-ad-client="ca-pub-7755842634690101"
        data-ad-slot="2220983665"
        style={{display: "inline-block",height:600,width:300}}
        ></ins>
     </div>
      //----</ return: render >----
    );
    //----</ HTML-Render >----
      //============</ Component >============
    }
  